// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, PrivateSet } from '@redwoodjs/router'

import AppLayout from 'src/layouts/AppLayout/AppLayout'
import AuthenticatorLayout from 'src/layouts/AuthenticatorLayout/AuthenticatorLayout'

import { useAuth } from './auth'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route path="/forbidden" page={ForbiddenPage} name="forbidden" />
      <Route path="/" page={LandingPage} name="landing" />
      <Route path="/login" page={LoginPage} name="login" />
      <PrivateSet wrap={[AppLayout]} unauthenticated="forbidden" roles="ww-user">
        <Route path="/admin" redirect="/admin/users" />
        <Route path="/admin/" redirect="/admin/users" />
        <Route path="/admin/support" page={AdminSupportPage} name="adminSupport" />
        <Route path="/admin/aircrafts" page={AdminAircraftsPage} name="adminAircrafts" />
        <Route path="/admin/feature-settings" page={FeatureSettingsPage} name="featureSettings" />
        <Route path="/admin/{component}" page={AdministrationPage} name="administration" />
        <Route path="/admin/{component}/{componentId}" page={AdministrationPage} name="administrationId" />
      </PrivateSet>
      {
        //AuthenticatorLayout is required to set the active org
      }
      <PrivateSet wrap={[AuthenticatorLayout, AppLayout]} unauthenticated="login">
        <Route path="/{orgName}" redirect="fleetDashboard" name="orgHomePage" />
        <Route path="/{orgName}/due-list" page={DueListPage} name="dueList" />
        <Route path="/{orgName}/maintenance-items" page={MaintenanceItemsPage} name="maintenanceItems" />
        <Route path="/{orgName}/create-task" page={CreateTaskPage} name="createTask" />
        <Route path="/{orgName}/aircraft/{id}" page={PlaneDashboardPage} name="planeDashboard" />
        <Route path="/{orgName}/onboarding/aircraft/{id}" page={AircraftOnboardingPage} name="aircraftOnboarding" />
        <Route path="/{orgName}/compliance/{ledgerId}" page={BulkCompliancePage} name="bulkCompliance" />
        <Route path="/{orgName}/compliance/{ledgerId}/sign" page={BulkComplianceSignPage} name="bulkComplianceSign" />
        <Route path="/{orgName}/maintenance/{id}" page={MaintenanceItemPage} name="maintenanceItem" />
        <Route path="/{orgName}/fleet-dashboard" page={FleetDashboardPage} name="fleetDashboard" />
        <Route path="/{orgName}/logbook-entries" page={LogbookEntriesLandingPage} name="logbookEntriesLanding" />
        <Route path="/{orgName}/ledger-logbook-entry/{ledgerId}" page={LogbookEntryPage} name="ledgerLogbookEntry" />
        <Route path="/{orgName}/logbook-entry/{entryId}" page={LogbookEntryPage} name="logbookEntry" />
        <Route path="/{orgName}/work-completed" page={WorkCompletedPage} name="workCompleted" />
        <Route path="/{orgName}/work-order" redirect="/{orgName}/work-orders/scheduled" />
        <Route path="/{orgName}/work-order/{id}" page={WorkOrderPage} name="workOrder" />
        <Route path="/{orgName}/work-orders/{ordersDiscriminator}" page={WorkOrdersPage} name="workOrders" />
        <Route path="/{orgName}/work-order-compliance/{workOrderId}" page={WorkOrderCompliancePage} name="workOrderCompliance" />
        <Route path="/{orgName}/invoice/{id}" page={InvoicePage} name="invoice" />
        <Route path="/{orgName}/activity-log" page={ActivityLogPage} name="activityLog" />
        <Route path="/{orgName}/purchase-orders" page={PurchaseOrdersPage} name="purchaseOrders" />
        <Route path="/{orgName}/purchase-order/{id}" page={PurchaseOrderDetailPage} name="purchaseOrder" />
        <Route path="/{orgName}/purchase-order/{id}/ingest" page={PurchaseOrderIngestPage} name="purchaseOrderIngest" />
        <Route path="/{orgName}/document-hub" page={DocumentHubPage} name="documentHub" />
        <Route path="/{orgName}/document-hub/{aircraftId}" page={DocumentHubPage} name="documentHubByAircraft" />
        <Route path="/{orgName}/reports" page={ReportsPage} name="reports" />
        <Route path="/{orgName}/inbox" page={InboxPage} name="inbox" />
        <Route path="/{orgName}/settings" page={SettingsPage} name="settings" />
        <Route path="/{orgName}/feature-not-enabled" page={FeatureNotEnabledPage} name="featureNotEnabled" />

        <Route path="/{orgName}/products" page={ProductsListPage} name="productsList" />
        <Route path="/{orgName}/due-products" page={DueProductsPage} name="dueProducts" />
        <Route path="/{orgName}/products/{id}" page={ProductDetailPage} name="productDetail" />
        <Route path="/{orgName}/tools" page={ToolsListPage} name="toolsList" />

        <Route path="/{orgName}/item/{id}" page={InventoryItemDetailPage} name="inventoryItemDetail" />
      </PrivateSet>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
