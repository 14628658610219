import { uuidRegex } from './uuidRegex'

export const COMMENT_HASH_REGEX = /#cm_\w+/

export const SUPPORT_USER_ID = 'SUPPORT_USER'

// ! If you're adding or changing values in this array, make sure to also update the commentRoomNameResolvers in web/src/layouts/AppLayout/CustomLiveblocksProvider.tsx
export const COMMENT_ROOM_TYPES = [
  'fleetDashboard',
  'aircraftDashboard',
  'reports',
  'documentHub',
  'dueList',
  'maintenanceItems',
  'compliance',
  'workCompleted',
  'ledgerLogbookEntry',
  'logbookEntry',
  'logbookEntries',
  'activityLog',
  'purchaseOrder',
  'purchaseOrders',
  'workOrder',
  'myWorkOrders',
  'scheduledWorkOrders',
  'completedWorkOrders',
  'invoice',
  'products',
  'product',
  'dueProducts',
  'tools',
  'inventoryItem',
] as const

export type CommentRoomType = (typeof COMMENT_ROOM_TYPES)[number]

export const COMMENT_ROOM_URL_MATCHERS: { [key in CommentRoomType]: RegExp } = {
  fleetDashboard: /fleet-dashboard$/,
  aircraftDashboard: new RegExp(`aircraft/${uuidRegex}`),
  reports: /reports$/,
  documentHub: /document-hub/,
  dueList: /due-list/,
  maintenanceItems: /maintenance-items/,
  compliance: new RegExp(`compliance/(${uuidRegex})`),
  workCompleted: /work-completed/,
  ledgerLogbookEntry: new RegExp(`ledger-logbook-entry/${uuidRegex}`),
  logbookEntry: new RegExp(`logbook-entry/${uuidRegex}`),
  logbookEntries: /logbook-entries/,
  activityLog: /activity-log/,
  purchaseOrder: /purchase-orders$/,
  purchaseOrders: new RegExp(`purchase-order/${uuidRegex}`),
  workOrder: new RegExp(`work-order/${uuidRegex}`),
  myWorkOrders: /work-orders\/myorders/,
  scheduledWorkOrders: /work-orders\/scheduled/,
  completedWorkOrders: /work-orders\/completed/,
  invoice: new RegExp(`invoice/${uuidRegex}`),
  product: new RegExp(`products/${uuidRegex}`),
  dueProducts: /due-products/,
  products: /products/,
  tools: /tools/,
  inventoryItem: new RegExp(`item/${uuidRegex}`),
}
