import clsx from 'clsx'
import { InboxNotificationThreadData } from '@liveblocks/core'
import { InboxNotification } from '@liveblocks/react-ui'
import { unreadNotificationBackground } from './Notification'

export type ThreadNotificationData = InboxNotificationThreadData & {
  link?: string
}

const ThreadNotification = ({
  notification,
}: {
  notification: ThreadNotificationData
}) => {
  return (
    <InboxNotification
      className={clsx('rounded-md', {
        [unreadNotificationBackground]: !notification.readAt,
      })}
      inboxNotification={notification}
      href={notification.link}
      kinds={{
        thread: (props) => <InboxNotification.Thread {...props} />,
      }}
    />
  )
}

export default ThreadNotification
