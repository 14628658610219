import { UPLOADER_EVENTS, useUploady } from '@rpldy/uploady'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  DELETE_INVALID_FILE,
  UPDATE_FILE_HASH,
  UPDATE_FILE_SIZE,
} from 'src/hooks/requests/useUploadFile'

const useUploadEventListeners = () => {
  const { on, off } = useUploady()
  const [isUploading, setIsUploading] = React.useState(false)
  const [updateFileHash] = useMutation(UPDATE_FILE_HASH)
  const [updateFileSize] = useMutation(UPDATE_FILE_SIZE)
  const [deleteInvalidFile] = useMutation(DELETE_INVALID_FILE)
  React.useEffect(() => {
    //upload start listener
    const itemStartHandler = (item) => {
      setIsUploading(true)
    }
    on(UPLOADER_EVENTS.ITEM_START, itemStartHandler)

    //upload add to queue listener
    const batchAddHandler = (batch) => {
      toast.loading(`Uploading ${batch.items[0].file.name} 0%`, {
        id: batch.items[0].id,
      })
    }
    on(UPLOADER_EVENTS.BATCH_ADD, batchAddHandler)

    //upload progress listener
    const itemProgressHandler = (item) => {
      toast.loading(
        `Uploading ${item.file.name} ${Math.round(item.completed)}%`,
        {
          id: item.id,
        }
      )
    }
    on(UPLOADER_EVENTS.ITEM_PROGRESS, itemProgressHandler)

    //finished uploading listener
    const itemFinishHandler = async (item, options) => {
      setIsUploading(false)
      const searchParams = new URLSearchParams(window.location.search)
      const delay = parseInt(searchParams.get('delay') || '0') * 1000

      setTimeout(async () => {
        await updateFileSize({
          variables: {
            id: options.params.fileId,
            ext: item.file.name.split('.').pop(),
            localHash: options.params.localHash,
          },
          onError: async (error) => {
            toast.error(`${item.file.name} upload failed`, {
              duration: 3000,
              id: item.id,
            })
            options.params.reject(error)
          },
          onCompleted: () => {
            toast.success(`${item.file.name} uploaded successfully`, {
              duration: 3000,
              id: item.id,
            })
            options.params.resolve(options.params.fileId)
          },
        })
        // await updateFileHash({
        //   variables: {
        //     id: options.params.fileId,
        //     ext: item.file.name.split('.').pop(),
        //     localHash: options.params.localHash,
        //   },
        //   onError: async (error) => {
        //     toast.error(`${item.file.name} upload failed`, {
        //       duration: 3000,
        //       id: item.id,
        //     })
        //     options.params.reject(error)
        //   },
        //   onCompleted: () => {
        //     toast.success(`${item.file.name} uploaded successfully`, {
        //       duration: 3000,
        //       id: item.id,
        //     })
        //     options.params.resolve(item.file)
        //   },
        // })
      }, delay)
    }

    on(UPLOADER_EVENTS.ITEM_FINISH, itemFinishHandler)

    //upload error listener
    const itemErrorHandler = async (item, options) => {
      setIsUploading(false)
      toast.error(`${item.file.name} upload failed`, {
        duration: 3000,
        id: item.id,
      })
      await deleteInvalidFile({
        variables: {
          id: options.params.fileId,
        },
      })
      options.params.reject(new Error(`Upload of ${item.file.name} failed`))
    }

    on(UPLOADER_EVENTS.ITEM_ERROR, itemErrorHandler)

    //unregister listeners
    return () => {
      off(UPLOADER_EVENTS.ITEM_START, itemStartHandler)
      off(UPLOADER_EVENTS.BATCH_ADD, batchAddHandler)
      off(UPLOADER_EVENTS.ITEM_PROGRESS, itemProgressHandler)
      off(UPLOADER_EVENTS.ITEM_FINISH, itemFinishHandler)
      off(UPLOADER_EVENTS.ITEM_ERROR, itemErrorHandler)
    }
  }, [])

  React.useEffect(() => {
    const handleUnload = (event) => {
      if (isUploading) {
        event.preventDefault()
        event.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', handleUnload)
    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [isUploading])
}

export default useUploadEventListeners
