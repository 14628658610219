import { CommentNotificationData } from 'src/components/Notifications/NotificationsList'

const getNewNotifications = (
  previousNotifications: CommentNotificationData[],
  currentNotifications: CommentNotificationData[]
) => {
  return currentNotifications.filter((n) => {
    const previousNotification = previousNotifications.find(
      (previousNotification) => previousNotification.id === n.id
    )
    return (
      !previousNotification ||
      (previousNotification.notifiedAt < n.notifiedAt &&
        n.notifiedAt > n.readAt)
    )
  })
}

export default getNewNotifications
