import {
  InboxNotificationData,
  InboxNotificationThreadData,
} from '@liveblocks/core'

const isThreadNotification = (
  notification: InboxNotificationData
): notification is InboxNotificationThreadData => {
  return 'threadId' in notification && notification.threadId !== undefined
}

export default isThreadNotification
