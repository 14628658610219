import {
  COMMENT_ROOM_URL_MATCHERS,
  CommentRoomType,
} from '@wingwork/common/src/constants/comments'

const getCommentRoomType = (
  url: string,
  orgSlug: string
): CommentRoomType | undefined => {
  const urlWithoutOrg = url.replace(`\/${orgSlug}\/`, '')
  return Object.keys(COMMENT_ROOM_URL_MATCHERS).find((key) =>
    COMMENT_ROOM_URL_MATCHERS[key].test(urlWithoutOrg)
  ) as CommentRoomType | undefined
}

export default getCommentRoomType
