import { Composer } from '@liveblocks/react-ui'
import { useThreads } from '@liveblocks/react'
import { styled, Tab, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import CommentThreadList from 'src/components/Comments/CommentThreadList'
import useUserId from 'src/hooks/useUserId'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Loading from 'src/components/Loading'
import CommentsDrawerScaffold from 'src/components/Comments/CommentsDrawerScaffold'
import CommentsDrawerHeader from 'src/components/Comments/CommentsDrawerHeader'
import { getMentionedIdsFromCommentBody, ThreadData } from '@liveblocks/client'
import { useLocation } from '@redwoodjs/router'
import { COMMENT_HASH_REGEX } from '@wingwork/common/src/constants/comments'
import { useOrgName } from 'src/hooks/useOrgName'
import Sentry from 'src/lib/sentry'
import LoadingCommentsDrawer from 'src/components/Comments/LoadingCommentsDrawer'

const StyledComposer = styled(Composer)(() => ({
  boxShadow: '0 -2px 10px 0 rgba(0,0,0, 0.10)',
}))

const StyledTabPanel = styled(TabPanel)(() => ({
  padding: 3,
}))

const isUserThread = (thread: ThreadData, currentUserId: string) => {
  return thread.comments.some(
    (comment) =>
      comment.userId === currentUserId ||
      getMentionedIdsFromCommentBody(comment.body).includes(currentUserId)
  )
}

const CommentsDrawerContent = () => {
  const { id: userId } = useUserId()
  const { hash, pathname } = useLocation()
  const orgSlug = useOrgName()
  const [showResolved, setShowResolved] = useState(false)
  const [selectedTab, setSelectedTab] = useState('on-this-page')

  let threads: ReturnType<typeof useThreads>['threads'] | undefined
  let threadsError: ReturnType<typeof useThreads>['error'] | undefined
  let isLoading: ReturnType<typeof useThreads>['isLoading'] | undefined
  try {
    const threadsResponse = useThreads({
      query: { resolved: showResolved ? undefined : false },
    })
    threads = threadsResponse.threads
    threadsError = threadsResponse.error
    isLoading = threadsResponse.isLoading
  } catch (err) {
    Sentry.captureException(err)
  }

  const userThreads = useMemo(() => {
    return (threads ?? []).filter((thread) => isUserThread(thread, userId))
  }, [threads, userId])
  const threadMetadata: { urlOverride?: string } = {}
  if (pathname.match(`/${orgSlug}/document-hub/*`)) {
    threadMetadata.urlOverride = pathname
  }

  // If we're navigating directly to a comment but that comment isn't in the retrieved threads, try showing resolved comments
  useEffect(() => {
    if (hash && hash.match(COMMENT_HASH_REGEX)) {
      const commentIsInThreads = threads?.some((thread) =>
        thread.comments.some((comment) => comment.id === hash.replace('#', ''))
      )
      if (!commentIsInThreads) {
        setShowResolved(true)
      }
    }
  }, [hash])

  if (threadsError) {
    return (
      <CommentsDrawerScaffold>
        <Typography variant="h6" className="p-3 text-center">
          Error loading comments
        </Typography>
      </CommentsDrawerScaffold>
    )
  }

  if (!threads && isLoading) {
    return <LoadingCommentsDrawer />
  }

  return (
    <CommentsDrawerScaffold>
      <CommentsDrawerHeader
        showResolved={showResolved}
        setShowResolved={setShowResolved}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <TabContext value={selectedTab}>
            <TabList
              onChange={(_, newValue) => setSelectedTab(newValue)}
              aria-label="Comment selection tabs"
              variant="fullWidth"
              className="border-[0] border-b border-solid border-gray-300"
            >
              <Tab label="On this page" value={'on-this-page'} />
              <Tab label="Involving you" value={'involving-you'} />
            </TabList>
            <div className="flex-grow overflow-y-auto">
              <StyledTabPanel value={'on-this-page'}>
                <CommentThreadList threads={threads ?? []} />
              </StyledTabPanel>
              <StyledTabPanel value={'involving-you'}>
                <CommentThreadList threads={userThreads} />
              </StyledTabPanel>
            </div>
          </TabContext>
          <StyledComposer className="composer" metadata={threadMetadata} />
        </>
      )}
    </CommentsDrawerScaffold>
  )
}

export default CommentsDrawerContent
