import { Drawer } from '@mui/material'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import {
  commentsDrawerExpandedToggled,
  selectCommentsDrawerExpanded,
} from 'src/slices/appLayoutSlice'
import clsx from 'clsx'
import { transitionOutClasses } from 'src/layouts/AppLayout/AppLayout'
import { transitionInClasses } from 'src/layouts/AppLayout/AppLayout'

export const commentsDrawerWidth = 400

const CommentsDrawerScaffold = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const dispatch = useDispatch()
  const open = useSelector(selectCommentsDrawerExpanded)

  return (
    <Drawer
      anchor={'right'}
      variant="persistent"
      sx={{
        width: commentsDrawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: commentsDrawerWidth,
          boxSizing: 'border-box',
          position: 'relative',
        },
        zIndex: (theme) => (open ? theme.zIndex.appBar - 1 : -1),
        position: 'relative',
      }}
      className={clsx({
        [transitionInClasses]: open,
        [transitionOutClasses]: !open,
      })}
      classes={{
        paper: clsx({
          [transitionInClasses]: open,
          [transitionOutClasses]: !open,
        }),
      }}
      open={open}
      onClose={() => dispatch(commentsDrawerExpandedToggled())}
    >
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {children}
      </div>
    </Drawer>
  )
}

export default CommentsDrawerScaffold
