import { styled } from '@mui/material'

const StyledThreadContainer = styled('div')(() => ({
  overflow: 'auto',
  flexGrow: 1,
  '.lb-thread': {
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
    borderRadius: '0.5rem',
  },
  '.lb-thread-comment': {
    borderRadius: '0.5rem 0.5rem 0 0',
  },
  '.lb-thread-composer': {
    borderRadius: '0 0 0.5rem 0.5rem',
  },
}))

const CommentThreadWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledThreadContainer className="space-y-1 p-1">
      {children}
    </StyledThreadContainer>
  )
}

export default CommentThreadWrapper
