import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import BuildIcon from '@mui/icons-material/BuildRounded'
import ProductionQuantityLimitsRoundedIcon from '@mui/icons-material/ProductionQuantityLimitsRounded'
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import ClearAllRoundedIcon from '@mui/icons-material/ClearAllRounded'
import HandymanOutlined from '@mui/icons-material/HandymanOutlined'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined'
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined'
import HistoryIcon from '@mui/icons-material/History'
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import PeopleOutlineOutlined from '@mui/icons-material/PeopleOutlineOutlined'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import PurchaseOrderIcon from '@mui/icons-material/RequestPageOutlined'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import SnippetFolderOutlinedIcon from '@mui/icons-material/SnippetFolderOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import InventoryOutlined from '@mui/icons-material/Inventory2Outlined'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Drawer as MUIDrawer,
  Toolbar,
  Tooltip,
} from '@mui/material'
import clsx from 'clsx'

import { Link, routes, useLocation, useParams } from '@redwoodjs/router'
import { Star } from '@mui/icons-material'
import useHasFeature from 'src/hooks/useHasFeature'
import useIsAdmin from 'src/hooks/useIsAdmin'

type ListItem = {
  text: string
  icon: React.ReactElement
  to?: string
}

const ListGroup = ({
  title,
  items,
  expanded,
}: {
  title?: string
  items: ListItem[]
  expanded: boolean
}) => (
  <List
    subheader={
      title && (
        <ListSubheader
          className={`h-4 normal-case ${
            expanded ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {title}
        </ListSubheader>
      )
    }
  >
    {items.map(({ text, icon, to, disabled }) => (
      <ListItem key={text} disablePadding>
        <Tooltip
          title={text}
          placement="right"
          slotProps={{
            // offset used to snug the tooltip to the button just right
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -7],
                  },
                },
              ],
            },
          }}
        >
          <ListItemButton
            disabled={!to || disabled}
            component={to && Link}
            to={to && to}
            className={`min-h-6 px-[15px] py-[6px] ${
              expanded ? 'justify-[initial]' : 'justify-center'
            }`}
          >
            <ListItemIcon
              className={`min-w-0 justify-center ${expanded ? 'mr-3' : 'mr-0'}`}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={text}
              className={expanded ? 'opacity-100' : 'opacity-0'}
            />
          </ListItemButton>
        </Tooltip>
      </ListItem>
    ))}
  </List>
)

const INTERNAL_PATH_NAMES = ['/admin', '/onboarding']

export const navbarExpandedWidth = 240
export const navbarMiniWidth = 56

const Drawer = ({ expanded = true }: { expanded?: boolean }) => {
  const { pathname } = useLocation()
  const pathParams = useParams()

  const isAdmin = useIsAdmin()
  const isAdminPage = React.useMemo(
    () => INTERNAL_PATH_NAMES.some((path) => pathname.startsWith(path)),
    [pathname]
  )
  const orgName = !isAdminPage ? pathParams.orgName : undefined
  const { hasFeature: inventoryEnabled } = useHasFeature('Inventory', orgName)
  const { hasFeature: orgHasComments } = useHasFeature('Comments', orgName)

  const sidebarNavigationGroups = React.useMemo(() => {
    if (isAdminPage) {
      return {
        Manage: [
          {
            text: 'Users',
            icon: <PeopleOutlineOutlined />,
            to: routes.administration({ component: 'users' }),
          },
          {
            text: 'Organizations',
            icon: <CorporateFareOutlinedIcon />,
            to: routes.administration({ component: 'organizations' }),
          },
          {
            text: 'Aircraft',
            icon: <FlightTakeoffOutlinedIcon />,
            to: routes.administration({ component: 'aircrafts' }),
          },
          {
            text: 'Support',
            icon: <SupportAgentIcon />,
            to: routes.adminSupport(),
          },
          {
            text: 'Features',
            icon: <Star />,
            to: routes.featureSettings(),
          },
          {
            text: 'Activity',
            icon: <QueryStatsIcon />,
            to: routes.administration({ component: 'activity' }),
          },
        ],
      }
    } else {
      return {
        Analytics: [
          {
            text: 'Dashboard',
            icon: <DashboardOutlinedIcon />,
            to: routes.fleetDashboard({ orgName }),
          },
          {
            text: 'Reports',
            icon: <AutoGraphOutlinedIcon />,
            to: routes.reports({ orgName }),
          },
          {
            text: 'Document Hub',
            icon: <SnippetFolderOutlinedIcon />,
            to: routes.documentHub({ orgName }),
          },
        ],
        Maintenance: [
          {
            text: 'Due List',
            icon: <ClearAllRoundedIcon />,
            to: routes.dueList({ orgName }),
          },
          {
            text: 'Maintenance Items',
            icon: <HandymanOutlined />,
            to: routes.maintenanceItems({ orgName: orgName }),
          },
          {
            text: 'Past Compliance',
            icon: <AssignmentOutlinedIcon />,
            to: routes.workCompleted({ orgName }),
          },
          {
            text: 'Logbook Entries',
            icon: <WorkOutlineOutlinedIcon />,
            to: routes.logbookEntriesLanding({ orgName }),
          },
          {
            text: 'Activity Log',
            icon: <HistoryIcon />,
            to: routes.activityLog({ orgName }),
          },
          {
            text: 'Purchase Orders',
            icon: <PurchaseOrderIcon />,
            to: routes.purchaseOrders({ orgName }),
          },
        ],
        Inventory: [
          {
            text: 'Inventory',
            icon: <InventoryOutlined />,
            to: routes.productsList({ orgName }),
            disabled: !inventoryEnabled,
          },
          {
            text: 'Due Inventory',
            icon: <ProductionQuantityLimitsRoundedIcon />,
            to: routes.dueProducts({ orgName }),
            disabled: !inventoryEnabled,
          },
          {
            text: 'Tools',
            icon: <BuildIcon />,
            to: routes.toolsList({ orgName }),
            disabled: !inventoryEnabled,
          },
        ],
        'Work Orders': [
          {
            text: 'My Work Orders',
            icon: <EngineeringOutlinedIcon />,
            to: routes.workOrders({
              orgName,
              ordersDiscriminator: 'myorders',
            }),
          },
          {
            text: 'Scheduled',
            icon: <ScheduleRoundedIcon />,
            to: routes.workOrders({
              orgName,
              ordersDiscriminator: 'scheduled',
            }),
          },

          {
            text: 'Completed',
            icon: <CheckRoundedIcon />,
            to: routes.workOrders({
              orgName,
              ordersDiscriminator: 'completed',
            }),
          },
        ],
      }
    }
  }, [pathParams.orgName, pathname])

  const expandedDrawerClasses =
    'transition-all ease-in duration-300 overflow-x-hidden'
  const miniDrawerClasses =
    'transition-all ease-out duration-300 overflow-x-hidden'

  return (
    <MUIDrawer
      variant="permanent"
      anchor={'left'}
      sx={{
        zIndex: (theme) => theme.zIndex.appBar - 1,
        width: expanded ? navbarExpandedWidth : navbarMiniWidth,
        [`& .MuiDrawer-paper`]: {
          position: 'relative',
        },
        height: '100%',
        position: 'relative',
      }}
      className={clsx({
        ['box-sizing-border flex-shrink-0 whitespace-nowrap']: true,
        [expandedDrawerClasses]: expanded,
        [miniDrawerClasses]: !expanded,
      })}
      classes={{
        paper: clsx({
          [expandedDrawerClasses]: expanded,
          [miniDrawerClasses]: !expanded,
        }),
      }}
    >
      {Object.keys(sidebarNavigationGroups).map((key) => {
        const items = sidebarNavigationGroups[key]
        return (
          <ListGroup key={key} title={key} items={items} expanded={expanded} />
        )
      })}

      <div style={{ flexGrow: 1 }} />
      <List>
        <Divider />
        {!isAdminPage ? (
          <ListGroup
            items={[
              ...(orgHasComments
                ? [
                    {
                      text: 'Inbox',
                      icon: <InboxOutlinedIcon />,
                      to: routes.inbox({ orgName }),
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      text: 'Admin Settings',
                      icon: <AdminPanelSettingsIcon />,
                      to: routes.administration({ component: 'users' }),
                    },
                  ]
                : []),
              {
                text: 'Settings',
                icon: <SettingsIcon />,
                to: routes.settings({ orgName }),
              },
            ]}
            expanded={expanded}
          />
        ) : (
          <ListGroup
            items={[
              {
                text: 'Back to App',
                icon: <ExitToAppIcon className="rotate-180" />,
                to: routes.landing(),
              },
            ]}
            expanded={expanded}
          />
        )}
      </List>
    </MUIDrawer>
  )
}

export default Drawer
