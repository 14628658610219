import { useUploady } from '@rpldy/uploady'
import { AttachmentType } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Sentry from 'src/lib/sentry'
import { getMd5 } from 'src/utils/helpers'

import { useOrgName } from '../useOrgName'

const CREATE_FILE_BULK_ATTACH_MUTATION = gql`
  mutation CreateFileBulkAttachMutation(
    $input: CreateFileInput!
    $attachTos: [AttachToInput!]!
    $localHash: String!
  ) {
    createFileBulkAttach(
      input: $input
      attachTos: $attachTos
      localHash: $localHash
    ) {
      id
      signedUploadUrl
      originalFilename
    }
  }
`

//! const INGEST_FILE_INTO_R2R_MUTATION = gql`
//!   mutation IngestFileIntoR2rMutation($fileId: String!) {
//!     ingestFileIntoR2r(fileId: $fileId) {
//!       id
//!     }
//!   }
//! `

export const UPDATE_FILE_HASH = gql`
  mutation UpdateFileHash($id: String!, $ext: String!, $localHash: String!) {
    updateFileHash(id: $id, ext: $ext, localHash: $localHash) {
      id
    }
  }
`

export const UPDATE_FILE_SIZE = gql`
  mutation UpdateFileSize($id: String!, $ext: String!, $localHash: String!) {
    updateFileSize(id: $id, ext: $ext, localHash: $localHash) {
      id
    }
  }
`

export const DELETE_INVALID_FILE = gql`
  mutation DeleteInvalidFile($id: String!) {
    deleteInvalidFile(id: $id) {
      id
    }
  }
`

const useUploadFile = () => {
  const [createFileBulkAttach] = useMutation(CREATE_FILE_BULK_ATTACH_MUTATION)
  //! const [ingestFileIntoR2r] = useMutation(INGEST_FILE_INTO_R2R_MUTATION)
  const orgName = useOrgName()
  const { upload } = useUploady()

  const handleFailedUpload = (toastId) => (error) => {
    toast.error('Failed to upload file', { id: toastId })
    console.error(error)
    Sentry.captureException(error)
  }

  const handleUpload = async (
    file: File,
    attachables: { id: string; type: AttachmentType }[] = [],
    aircraftId?: string
  ): Promise<void> => {
    const localHash = await getMd5(file)

    return new Promise((resolve, reject) => {
      createFileBulkAttach({
        variables: {
          input: {
            originalFilename: file.name,
          },
          attachTos: attachables.map((attachable) =>
            attachable.id && attachable.type ? attachable : undefined
          ),
          localHash: localHash,
        },
        onError: (error) => {
          reject(error)
        },
        onCompleted: async (data) => {
          const fileId = data.createFileBulkAttach.id
          if (
            data.createFileBulkAttach.signedUploadUrl === 'File already exists'
          ) {
            toast.success(
              `${data.createFileBulkAttach.originalFilename} Attached`
            )
            resolve(data.createFileBulkAttach.id)
            return
          }

          upload(file, {
            destination: {
              url: data.createFileBulkAttach.signedUploadUrl,
              headers: {
                'Access-Control-Allow-Origin': '*', // Required for CORS support to work
              },
              method: 'PUT',
            },
            params: {
              fileId,
              localHash,
              resolve,
              reject,
            },
            sendWithFormData: true,
          })
          // TODO: Await upload and then ingest file into R2R ingestFileIntoR2r
          //! ingestFileIntoR2r({
          //!   variables: {
          //!     input: {
          //!       fileId,
          //!     },
          //!   },
          //! })
        },
      })
    })
  }

  return handleUpload
}

export default useUploadFile
