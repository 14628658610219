import { Switch, Typography } from '@mui/material'

type CommentsDrawerHeaderProps = {
  showResolved: boolean
  setShowResolved: React.Dispatch<React.SetStateAction<boolean>>
  showResolvedDisabled?: boolean
}

const CommentsDrawerHeader = ({
  showResolved,
  setShowResolved,
  showResolvedDisabled,
}: CommentsDrawerHeaderProps) => {
  return (
    <div className="flex items-center justify-between px-2 pb-1 pt-2">
      <Typography variant="h6">Comments</Typography>
      <div>
        <Typography variant="caption" className="select-none">
          Show Resolved
        </Typography>
        <Switch
          onChange={(_, checked) => setShowResolved(checked)}
          checked={showResolved}
          disabled={showResolvedDisabled}
        />
      </div>
    </div>
  )
}

export default CommentsDrawerHeader
