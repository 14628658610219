import { IN_PROGRESS_COMPLIANCE_STATUSES } from '@wingwork/common/src/constants'
import { LazyQueryExecFunction } from '@apollo/client'

import { COMMENT_ROOM_URL_MATCHERS } from '@wingwork/common/src/constants'
import { ComplianceLedger } from 'types/graphql'
import { ThreadData } from '@liveblocks/client'

export const GET_COMPLIANCE_LEDGER_STATUS = gql`
  query GetComplianceLedgerStatus($id: String!) {
    complianceLedger(id: $id) {
      id
      status
    }
  }
`

const getThreadLink = async (
  thread: ThreadData,
  orgSlug: string,
  getComplianceLedgerStatus: LazyQueryExecFunction<
    ComplianceLedger,
    {
      id: string
    }
  >
) => {
  // For compliance, we want comments to carry over from in progress compliance to completed compliance, so the link for the notification depends on the status of the compliance
  if (thread.roomId.match(COMMENT_ROOM_URL_MATCHERS.compliance)) {
    const complianceLedgerId = thread.roomId.match(
      COMMENT_ROOM_URL_MATCHERS.compliance
    )[1]
    const complianceLedgerStatusResult = await getComplianceLedgerStatus({
      variables: { id: complianceLedgerId },
    })
    if (
      !IN_PROGRESS_COMPLIANCE_STATUSES.includes(
        complianceLedgerStatusResult?.data?.complianceLedger?.status
      )
    ) {
      return `/${orgSlug}/ledger-logbook-entry/${complianceLedgerId}`
    }
  }
  return (thread?.metadata.urlOverride as string | undefined) || thread.roomId
}

export default getThreadLink
